import React from "react"

export default function About(props) {
    return (
        <div className="container" style={{paddingTop: "10px"}}>
            <h1 className="title">About</h1>

            <p>This is a chat app built using React.</p>
        </div>
    )
}
